.image-warranty-home-page {
    width: 500px;
    height: 500px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }

.image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-fit {
  
  --color-activated: red;
}

.p1 {
    font-size: small;
}

  .t-image {
    position: relative; /* Declared position allows for location changes */
    top: -2px; /* Moves the image 2px closer to the top of the page */
}

.dark {
  background-color: black;
}

.light {
  background-color: white;
  color: black;
}

.textCenter {
  text-align: center;
  justify-content: center;
}

.textMod {
  color: red;
}

.round {
  --width: 60px;
  --height: 120px;
  --vertical-align: middle;
  --padding-start: 10px;
  --padding-end: 10px;
  --margin-top: 50px;
  --padding-top: 25px;
  --padding-bottom: 25px;
}

.yellowText {
  color: #F1D549;
}

.smallText {
  font-size: 5px !important;
}